@charset "utf-8";



// Fonts
@import url('https://fonts.googleapis.com/css?family=Jost:100,200,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,200,300,400,500,600,700,800,900');

$FONT_FAMILY_LOGO: "Jost", sans-serif;
$FONT_FAMILY_HEADING: "Montserrat", sans-serif;
$FONT_FAMILY_TEXT: "Roboto", sans-serif;

$body-family: $FONT_FAMILY_TEXT;

// Colors
$COLOR_BACKGROUND: #161D84;
$COLOR_TEXT: #FFFFFF; // over background
$SUBCOLOR1: #5E4AE3;
$SUBCOLOR2: #3FA5A7;

$primary: $COLOR_TEXT;
$link: $COLOR_BACKGROUND;
$info: $SUBCOLOR1;
$success: $SUBCOLOR2;

$button-color: $COLOR_TEXT;
$title-family: $FONT_FAMILY_HEADING;
$title-color: $COLOR_BACKGROUND;
$subtitle-family: $FONT_FAMILY_HEADING;

//$widescreen-enabled: false;
//$fullhd-enabled: false;

// Navigation
$navbar-background-color: $COLOR_BACKGROUND;

$navbar-burger-color: $COLOR_TEXT;
$navbar-divider-background-color: $COLOR_TEXT;

$navbar-box-shadow-color: $COLOR_TEXT;

$navbar-item-color: $COLOR_TEXT;
$navbar-item-active-color: $SUBCOLOR2;
$navbar-item-hover-color: $SUBCOLOR2;
$navbar-item-hover-background-color: $COLOR_BACKGROUND;

$navbar-dropdown-background-color: $COLOR_BACKGROUND;
$navbar-dropdown-arrow: $COLOR_TEXT;
$navbar-dropdown-item-hover-color: $COLOR_TEXT;
$navbar-dropdown-item-hover-background-color: $COLOR_TEXT;

// Footer

$footer-background-color: $COLOR_BACKGROUND;
$footer-padding: 1.5rem 1.5rem 1.5rem;
$footer-color: true;

// Columns

.button {
  height: auto !important;
  white-space: normal !important;
}


@import "../../node_modules/bulma/bulma";

//@import "../../node_modules/bulma/bulma.sass";
//@import "../../node_modules/bulma/sass/components/navbar";

//@import "../../node_modules/bulma/bulma.sass";
//@import "../../node_modules/bulma/bulma";
//@import "../../node_modules/bulma/sass/utilities/all";
//@import "../../node_modules/bulma/sass/base/all";
//@import "../../node_modules/bulma/sass/elements/button";
//@import "../../node_modules/bulma/sass/elements/container";
//@import "../../node_modules/bulma/sass/elements/title";
//@import "../../node_modules/bulma/sass/form/all";
//@import "../../node_modules/bulma/sass/components/navbar";
//@import "../../node_modules/bulma/sass/layout/hero.hbs";
//@import "../../node_modules/bulma/sass/layout/section";


// Logo

#app-nav .navbar-brand {
  margin: 10px;
}

#app-nav .navbar-brand .navbar-item, .logo-text {
  font-size: 20pt;
  font-weight: 500;
  font-family: $FONT_FAMILY_LOGO;
  color: $COLOR_TEXT;
  line-height: 1;
  padding: 8px 8px 8px 0px;
  margin-right: 15px;
  margin-left: 15px;
  max-width: 200px;
}

#app-nav .navbar-brand img {
  margin: 8px 8px 8px 15px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}


#app-nav .navbar-brand .navbar-burger {
  height: auto;
}

#app-nav-bar .navbar-item {
  font-size: 18px;
  font-weight: 500;
  font-family: $FONT_FAMILY_HEADING;
}

#app-nav .button {
  color: #4a4a4a;
}

a.navbar-burger:hover {
  color: $COLOR_TEXT;
}

body > .container {
  margin-top: 20px;
  margin-bottom: 20px;
}


// Footer
body > footer a, body > footer strong {
  color: $COLOR_TEXT;
}

body > footer a:hover {
  color: $COLOR_TEXT;
  text-decoration: underline;
}

body .container {
  padding: 20px;
}

// Columns reversed

@media(max-width: $desktop) {
  .columns.is-reversed-touch {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media(max-width: $tablet) {
  .columns.is-reversed-mobile {
    flex-direction: column-reverse;
    display: flex;
  }
}